<template>
    <div id="EventStatusCostCenter">
        <v-container fluid app>
            <v-card>
                <v-row no-gutters class="d-flex align-center justify-start mx-2 pt-0">
                    <v-col class="d-flex justify-start ml-2">
                        <div class="d-flex align-start mr-4">
                            <v-icon x-large color="primary" style="margin-top: 6px;">
                                {{ tableIcon }}
                            </v-icon>
                        </div>
                        <div class="d-flex align-center">
                            <span class="text-h6 font-weight-regular"
                                style="white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                            >
                                {{ tableName }}
                            </span>
                        </div>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col class="d-flex justify-start">
                        <v-text-field
                            v-model="search"
                            class="mb-6 mr-4"
                            append-icon="mdi-magnify"
                            label="Pesquisar"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on=" on "
                                min-width="48px"
                                @click="getWorkflows()"
                                :disabled="!event"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn color="primary" class="mb-2 mr-2 pa-0"
                                v-on="{ ...tooltip }"
                                min-width="48px"
                                @click="$router.push('/')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                </v-row>

                <v-divider class="mt-1 mb-2"></v-divider>

                <v-layout>
                    <v-flex xs4>
                        <v-autocomplete
                            v-model="event"
                            :items="events"
                            @input="getPurposes()"
                            class="mx-4 my-1"
                            item-text="description"
                            item-value="id"               
                            label="Evento"
                            return-object
                            persistent-hint
                        ></v-autocomplete>
                    </v-flex>
                    <!-- <v-flex xs4>
                        <v-autocomplete
                            v-model="workflow"
                            :items="workflows"
                            class="mx-4 my-1"
                            item-text="text"
                            item-value="id"
                            multiple
                            label="Fluxo de aprovação"
                            persistent-hint
                        ></v-autocomplete>
                    </v-flex> -->
                    <!-- FLUXO DE APROVAÇÃO -->
                    <v-flex xs4>
                        <div @click="setBackupWorkflow()">
                            <v-autocomplete
                                v-model="workflow"
                                :items="loadingWorkflow ? [] : workflows"
                                :label="'Fluxo de aprovação'"
                                item-text="text"
                                item-value="id"  
                                multiple
                                :loading="loadingWorkflow"
                                class="mx-2"
                                style="z-index: 1000 !important;"
                                @blur="confirmPurposeSelection()"
                                :no-data-text="loadingWorkflow ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingWorkflow">
                                    <template v-if="loadingWorkflow">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="workflows.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllWorkflows()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!workflow.length ? 'gray' : 'primary'">
                                                    {{ selectAllWorkflowIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="workflow.length === workflows.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="workflow.length !== workflows.length"
                                        style="display: inline-block; max-width: 150px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${workflow.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </div>
                    </v-flex>
                    <!-- PROPÓSITO -->
                    <v-flex xs4>
                        <div @click="setBackupPurpose()">
                            <v-autocomplete
                                v-model="purpose"
                                :items="loadingPurpose ? [] : purposes"
                                :label="'Propósitos'"
                                multiple
                                :loading="loadingPurpose"
                                class="mx-2"
                                style="z-index: 1000 !important;"
                                return-object
                                @blur="confirmPurposeSelection()"
                                :no-data-text="loadingPurpose ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPurpose">
                                    <template v-if="loadingPurpose">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="purposes.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPurposes()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!purpose.length ? 'gray' : 'primary'">
                                                    {{ selectAllPurposeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="purpose.length === purposes.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="purpose.length !== purposes.length"
                                        style="display: inline-block; max-width: 150px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${purpose.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </div>
                    </v-flex>
                </v-layout>

                <!-- TAMBÉM AS COLUNAS DE DESCRIÇÃO DO NÍVEL E JUSTIFY -->
                
                <v-dialog v-model="dialog" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title> {{ cost_center }} </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mb-2 mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialog = false"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="event"
                                        :items="events"
                                        class="mx-4 my-1"
                                        item-text="description"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                        readonly
                                    ></v-select>
                                </v-flex>
                                <v-flex xs12>
                                    <v-select
                                        v-model="purposeModal"
                                        :items="purposes"
                                        class="mx-4 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Propósito"
                                        persistent-hint
                                        readonly
                                    ></v-select>
                                </v-flex>
                                <v-flex>
                                    <v-data-table
                                        :headers="headersLevels"
                                        :items="showHistory === true ? approvals : levels"
                                        :search="search"
                                        :loading="loading"
                                        :item-key="showHistory === true ? 'id_history' : 'id_level'"
                                        sort-by="Nome"
                                        class="elevation-0"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    :loading="loading"
                    item-key="id_cost_center"
                    sort-by="cost_center_description"
                    class="elevation-1"
                >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.color="{ item }">
                        <v-layout>
                            <v-icon
                                class="ml-4"
                                :color="item.color"
                            >
                                mdi-circle
                            </v-icon>
                        </v-layout>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.date="{ item }">
                        <v-layout>
                            {{item.date}}
                            <v-spacer></v-spacer>
                            <v-icon
                                class="mr-2"
                                @click="cost_center = item.cost_center_description, dialog = true, approvalListLevels(item)"
                            >
                                mdi-folder-open
                            </v-icon>
                        </v-layout>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment'
import lodash from 'lodash'

export default {
    name:'EventStatusCostCenter',

    computed: {
        endpoint() {
            return [
                this.$ipApprovalFlow,
                'event-status-cost-center',
                {
                    conditions: []
                }
            ]
        },
        selectAllPurposeIcon() {
            let icon = ''

            if (!this.purpose.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.purpose.length < this.purposes.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },
        selectAllWorkflowIcon() {
            let icon = ''

            if (!this.workflow.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.workflow.length < this.workflows.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        }
    },

    data: () => ({
        tableName: 'Consulta do status do fluxo de aprovação do evento',
        tableIcon: 'mdi-table-large',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        dialog: false,
        
        loading: false,
        search: '',

        workflows: [],
        workflow: [],

        loadingWorkflow: true,
        showWorkflow: false,
        backupWorkflow: null,

        events: [],
        event: null,

        cost_center: '',
        costCenters: [],
        costCenter: null,

        purposes: [],
        purpose: [],
        purposeModal: '',

        items: [],

        loadingPurpose: true,
        showPurpose: false,
        backupPurpose: null,

        headers: [
            {
                text: 'Semáforo', value: 'color'
            },
            {
                text: 'Fluxo de aprovação', value: 'approval_flow_description'
            },
            {
                text: 'Propósito', value: 'purpose_description'
            },
            {
                text: 'Centro de custo', value: 'cost_center_description'
            },
            {
                text: 'Status', value: 'status'
            },
            {
                text: 'Usuário', value: 'name'
            },
            {
                text: 'Data', value: 'date'
            }
        ],

        levels: [],
        headersLevels: [
            {
                text: 'Semáforo', value: 'id_status'
            },
            // {
            //     text: 'Níveis', value: 'id_level'
            // },
            {
                text: 'Descrição do nível', value: 'approval_flow_level_description'
            },
            {
                text: 'Status', value: 'status'
            },
            {
                text: 'Usuário', value: 'name'
            },
            {
                text: 'Data', value: 'date'
            },
            {
                text: 'Justificativa', value: 'justification'
            }
        ],
        approvals: [],
        showHistory: false
    }),
    
    watch: {
        dialog (val) {
             if (!val) this.showHistory = false
        }
    },

    created () {
        this.init()
    },

    methods: {
        async init () {
            await this.getEvents()
            await this.getWorkflowOptions()
            await this.getWorkflows()
        },

        async getEvents () {
            const payload = {
                filter: {
                    orderColumn: "description",
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 10
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipEvent + 'event/list-options', {...payload})
                if (res) {
                    this.events = res.data.rows
                    this.event = this.events.length ? this.events[0] : null
                    this.getPurposes()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getWorkflowOptions () {
            this.loadingWorkflow = true
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 10
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow/list-options', {...payload})
                if (res) {
                    this.workflows = res.data.rows
                    this.loadingWorkflow = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingWorkflow = false
            }
        },

        setAllWorkflows() {
            if (!this.workflow.length) {
                this.workflow = lodash.cloneDeep(this.workflows)
            } else {
                this.workflow = []
            }
        },

        setBackupWorkflow() {
            this.backupWorkflow = lodash.cloneDeep(this.workflow)
        },

        async confirmWorkflowSelection() {
            if (lodash.isEqual(this.purpose, this.backupWorkflow)) {
                return
            }

            this.getWorkflows()
        },

        async getPurposes() {
            this.loadingPurpose = true

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_planning_function",
                        operator: "=",
                        value: this.event.id_planning_function
                    },
                    {
                        AndOr: "AND",
                        column: "id_purpose_version",
                        operator: "=",
                        value: this.event.id_purpose_version
                    }
                ]
            }

            try {
                const purposeRes = await this.$http.post(this.$ipOrganization + 'purpose/list-options', { filter })
                if (purposeRes) {
                    this.purposes = purposeRes.data.rows

                    // let foundFirst = this.purposes.find((item, index) => index === 0)
                    // this.purpose.push(foundFirst)
                    this.loadingPurpose = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingPurpose = false
            }
        },

        setBackupPurpose() {
            this.backupPurpose = lodash.cloneDeep(this.purpose)
        },

        async confirmPurposeSelection() {
            if (lodash.isEqual(this.purpose, this.backupPurpose)) {
                return
            }

            this.getWorkflows()
        },

        setAllPurposes() {
            if (!this.purpose.length) {
                this.purpose = lodash.cloneDeep(this.purposes)
            } else {
                this.purpose = []
            }
        },

        async getWorkflows() {
            this.loading = true

            if (!this.event || !this.purpose) {
                return this.loading = false
            }

            const purposeId = []

            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_event",
                            operator: "=",
                            value: this.event.id
                        },
                    ]
                }
            }

            this.purpose.forEach((purpose) => {
                purposeId.push(purpose.id)
            })

            if (purposeId.length > 0) {
                payload.filter.conditions.push(
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "IN",
                        value: purposeId
                    }
                )
            }

            if (this.workflow.length > 0) {
                payload.filter.conditions.push(
                    {
                        AndOr: "AND",
                        column: "id_approval_flow",
                        operator: "IN",
                        value: this.workflow
                    }
                )
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-general', { ...payload })
                if (res) {
                    this.items = res.data.rows
                    this.items.forEach((workflow) => {
                        workflow.date ? workflow.date = moment(workflow.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
                
                this.loading = false
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        },

        async approvalListLevels (item) {
            this.approvalHistory(item)
            this.levels = []
            this.purposeModal = {
                id: item.id_purpose,
                text: item.purpose_description
            }
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: item.id_event
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_purpose',
                            operator: '=',
                            value: item.id_purpose
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_cost_center',
                            operator: '=',
                            value: item.id_cost_center
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-levels', { ...payload })
                if (res) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory (item) {
            this.purposeModal = {
                id: item.id_purpose,
                text: item.purpose_description
            }
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: item.id_event
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_purpose',
                            operator: '=',
                            value: item.id_purpose
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_cost_center',
                            operator: '=',
                            value: item.id_cost_center
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        }
    }
}
</script>

<style>
</style>